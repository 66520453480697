@import "styles/variables";

.newList {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 14px;

    span {
      font-weight: 600;
    }
  }

  form {
    :global {
      .ant-radio-group {
        margin-top: 20px;
        display: flex;
        align-items: center;

        label {
          display: flex;
          align-items: baseline;
        }
      }
    }
  }

  &.edit {
    padding: 0 15px;
  
    input[type="text"],
    textarea,
    .shareableLink {
      background: #f3f3f3;
      border: solid 0.7px rgba(151, 151, 151, 0.41);
    }

    .saveBtn {
      color: $primary-color;
      padding: 0;
      margin-right: 20px;
      margin-top: 10px;
    }

    .deleteBtn {
      color: $unavailable-color;
      margin-top: 10px;
    }
  
    .shareableLink {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #f0f0f0;
      font-size: 13px;
      span {
        padding: 0 15px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow:  ellipsis;
      }
    }
  }
}
