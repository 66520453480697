.wrapper {
  box-sizing: border-box;
  img{
    max-width: 100%;
    height: auto !important;
  }
  h2{
    display: block;
    text-align: center;
    color: rgb(68, 68, 68);
    font-family: "Nunito Sans", sans-serif;
    font-size: 30px;
    font-weight: bold;
    margin: 0;
    padding: 20px 0;
  }
  .boxes{
    margin-bottom: 100px;
  }
}
.homepage-banners-section{
  .side-banners{
    .sidebanner{
      margin-bottom: 10px;
      @media (min-width: 767px){
        .title{
          font-size: 18px;
          line-height: 22px;
        }
      }
      @media (min-width: 1220px){
        .title{
          font-size: 28px;
          line-height: 38px;
        }
      }
    }
  }
}

.backgroundType1{
  &.xmas{
    background-image: url("/xmas/apples.png");
    background-position-x: -50px;
  }
  @media (min-width: 480px) {
    background-image: url("https://instance12.admin.grandus.sk/files/attachment/2020-02/4129/53e814/tomato.png");
    background-position-x: 0px;
    background-position-y: 40px;
    background-repeat: no-repeat;
    background-size: 150px;
    background-color: transparent;
  }
  @media (min-width: 780px){
    background-size: 200px;
  }
  @media (min-width: 1280px){
    background-position-x: 0px;
    background-position-y: 70%;
    background-size: 250px;
  }
  @media (min-width: 1670px){
    background-position-x: 0px;
    background-position-y: 70%;
    background-size: 300px;
  }
}

.backgroundType2{
  &.xmas{
    background-image: url("/xmas/dried-oranges-cinnamon-anise-nuts.png");
  }
  @media (min-width: 480px) {
    background-image: url("https://instance12.admin.grandus.sk/files/attachment/2020-02/4054/9fd54a/homepage-avokado.png");
    background-position-x: 100%;
    background-position-y: 30px;
    background-size: 200px;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  @media (min-width: 480px){
    background-size: 150px;
  }
  @media (min-width: 780px){
    background-size: 200px;
  }
  @media (min-width: 1280px){
    background-size: 250px;
  }
  @media (min-width: 1670px){
    background-size: 350px;
  }
  
}