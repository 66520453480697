.labels {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .label {
      font-family: Merriweather, sans-serif;
      min-height: 20px;
      line-height: 20px;
      margin-bottom: 5px;
      padding: 0 20px;
      text-align: center;
      display: inline-block;
      font-size: 0.7em;
      color: white;
      background-color: orange;
      &.status {
        background-color: orange;
      }
      &.new {
        background-color: rgb(177, 13, 201);
      }
      &.favourite {
        background-color: rgb(85, 171, 47);
      }
      &.discount {
        background-color: #BE100F;
      }
      &.delivery {
        background-color: rgb(172, 172, 172);
      }
      &.gift {
        background-color: #ff0024;
      }
    }
    &.inline {
      margin-top: 10px;
      margin-bottom: 20px;
      position: static;
      align-items: center;
      top: 0;
      left: 0;
      flex-direction: row;
      .label {
        padding: 4px 12px;
        font-size: 1em;
        margin-right: 10px;
        margin-bottom: 0;
      }
    }
  }