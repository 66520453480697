.gallery {
    position: relative;

    :global{
        .nonAvailable{
            img{
                filter: grayscale(100%);
            }
        }
    }

    .mainImage {
        img {
            width: 100%;
            height: auto;
        }
    }
}