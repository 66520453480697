@import "styles/variables";

$width: 50px;
$height: 50px;

$widthSmall: 40px;
$heightSmall: 40px;

.arrow {
  display: none;
  background: #ffffff !important;
  box-sizing: border-box;
  color: $text-color;
  justify-content: center;
  align-items: center;
  z-index: 1;
  width: $widthSmall !important;
  height: $heightSmall !important;
  border-radius: $general-gutter / 2;
  box-shadow: $general-shadow;
  font-size: 0.8em;
  border-radius: 50%;
  opacity: 1;

  @media (min-width: $md) {
    width: $width !important;
    height: $height !important;
  }

  svg {
    fill: $text-color;
    width: 25px;
    height: 25px;
  }

  &:hover {
    background: $primary-color !important;
    svg {
      fill: #ffffff;
    }
  }
  &::before {
    content: "";
  }
  &.left {
    left: 10px;
    top: 45%;

    @media (min-width: $md) {
      left: 20px;
    }
  }
  &.right {
    right: 10px;
    top: 45%;

    @media (min-width: $md) {
      right: 20px;
    }
  }
  @media (min-width: $md) {
    display: flex;
  }
}