.loader {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255,255,255,0.6);

    &.hidden {
        display: none;
    }
}