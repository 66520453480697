.banner{
    position: relative;
    picture,img{
        width: 100%;
        display: block;
    }
    .overlay{
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        padding: 20px;
        background-color: rgba(17, 17, 17, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .title{
            color: rgb(255, 255, 255);
            display: block;
            font-family: "Nunito Sans", sans-serif;
            font-weight: 900;
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 5px;
            padding: 0 20px;
            text-align: center;
            text-shadow: rgba(0, 0, 0, 0.5) 0px 3px 10px;
        }
        .subtitle{
            color: rgb(255, 255, 255);
            display: block;
            font-family: Merriweather, sans-serif;
            font-size: 13px;
            line-height: 18px;
            text-align: center;
        }
        @media (min-width: 700px) {
            .title{
                font-size: 28px;
                line-height: 38px;
            }
        }
    }   
}