@import "styles/variables";

.addButton {
  position: relative;
  display: inline-block;

  .btn:not([disabled]) {
    margin: 2px 0;
    font-family: $font-nunito-sans;
    font-size: 12px;
    color: $primary-color;
    border: 0;
    background: none;
    cursor: pointer;
    padding: 0;

    &.large {
      font-size: 25px;
    }

    .dropdownIcon {
      margin-left: 5px;
    }
  }
}
