@import "styles/variables";
$gutter: 10px;

.productCardWrapper {
  display: block;
  // padding: 10px;
  transition: padding 0.3s;
  @media (max-width: $md) {
    // padding: 5px;
  }
  &:hover {
    padding-top: 1px;
  }
  .productCard {
    display: block;
    background-color: white;
    // box-shadow: rgba(0, 0, 0, 0.0980392) 0px 10px 30px 0px;
    box-shadow: rgb(0, 0, 0, 0.1) 0px 5px 15px 2px;

    & > a {
      display: block;
    }

    .wishlistButton {
      position: absolute;
      top: 2px;
      right: 2px;
      font-size: 20px;
    }

    .imageWrapper {
      position: relative;
      .image {
        width: 100%;
        height: auto;
        display: block;
        margin-bottom: $gutter;
        img {
          width: 100%;
          height: auto;
          display: block;
        }
      }
    }
    .content {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 10px;
      position: relative;
      @media (max-width: $md) {
        padding: 5px 10px;
      }
      .title {
        display: block;
        color: rgb(68, 68, 68);
        font-family: "Nunito Sans", sans-serif;
        font-size: 20px;
        font-weight: bold;
        height: 56px;
        line-height: 28px;
        overflow: hidden;
        margin-bottom: $gutter;
        position: relative;
        padding-right: 40px;
        @media (max-width: $md) {
          font-size: 15px;
          line-height: 20px;
          padding-right: 30px;
          padding: 0;
        }

        .wishlistButton {
          top: 5px;
          right: 0;

          @media (max-width: $md) {
            top: 0;
          }
        }
      }
      .subtitle {
        display: block;
        color: rgb(68, 68, 68);
        font-family: Merriweather, sans-serif;
        font-size: 11px;
        line-height: 11px;
        font-style: italic;
      }
      .description {
        color: rgb(68, 68, 68);
        display: block;
        font-family: Merriweather, sans-serif;
        font-size: 11px;
        font-weight: normal;
        height: 115px;
        line-height: 19px;
        overflow: hidden;
        margin-bottom: $gutter;
        @media (max-width: $md) {
          display: none;
          // font-size: 10px;
          // font-weight: lighter;
        }
      }
      .price {
        &.boxPrice {
          margin-top: 0 !important;
          margin-right: 10px !important;
        }
        .mainPrice {
          color: rgb(68, 68, 68);
          display: block;
          font-family: "Nunito Sans", sans-serif;
          font-size: 16px;
          font-weight: bold;
          height: 19px;
          line-height: 19px;
          white-space: nowrap;
          @media (max-width: $md) {
            font-size: 19px;
          }
        }
        .withoutVat {
          color: rgb(170, 170, 170);
          display: block;
          font-family: "Nunito Sans", sans-serif;
          font-size: 11px;
          height: 13px;
          line-height: 13px;
          &:after {
            content: " bez DPH";
          }
        }
        &.discount {
          .mainPrice {
            color: #be100f;
          }
        }
        &.withGutter {
          margin-top: 13px;
          @media (max-width: $md) {
            margin-top: 0;
          }
        }
      }
      .strikethroughPrice {
        text-decoration: none;
        position: relative;
        margin-right: 5px;
        margin-left: 10px;
        color: $text-color;
        font-size: 13px;
        &::before {
          top: 50%; /*tweak this to adjust the vertical position if it's off a bit due to your font family */
          background: $text-color; /*this is the color of the line*/
          opacity: 0.7;
          content: "";
          width: 110%;
          position: absolute;
          height: 0.1em;
          border-radius: 0.1em;
          left: -5%;
          white-space: nowrap;
          display: block;
          // transform: rotate(-15deg);
        }
      }
      .buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 32px;

        &.alignRight {
          justify-content: flex-end !important;
          & > div:first-of-type {
            width: 100% !important;
          }
        }
        & > div:first-of-type {
          display: flex;
          flex-direction: row;
          width: 100%;
          & > span {
            & > div {
              width: 65px;
              margin-right: 5px;
            }
          }

          :global(.ant-input-number) {
            width: 55px;
            min-height: 30px;

            @media (max-width: $sm) {
              :global(.ant-input-number-handler-wrap) {
                width: 100%;
                border-left: none;
              }
            }
            input {
              padding: 0 11px 0 6px;
            }
          }

          button {
            margin-left: 10px;
            padding: 4px 8px;
            // & > span:last-child {
            //   display: none;
            // }
          }
        }
        .fallback {
          font-size: 11px;
          margin-top: 5px;
          justify-content: flex-end;
          white-space: nowrap;
          margin-left: 10px;
        }
        @media (max-width: $lg) {
          height: 50px;
          padding-top: 5px;
          & > div:first-of-type {
            width: 100%;
            justify-content: space-between;
            & > span {
              display: flex;
              margin-bottom: 5px;
              align-items: center;
              & > div {
                width: 55px;
                margin-right: 5px;
              }
            }
          }
        }
        @media (max-width: $lg) {
          .fallback {
            margin-top: 5px;
            font-size: 0.8em;
            display: none;
          }
        }
      }
    }
    &.discountCard {
      .imageWrapper {
        .discount {
          position: absolute;
          top: 0;
          left: 0;
          width: 40px;
          height: 40px;
          margin-top: -12px;
          margin-left: -12px;
          background-color: rgb(250, 22, 22);
          border-radius: 50%;
          display: flex;
          color: white;
          justify-content: center;
          align-items: center;
          font-size: 0.7em;
          white-space: nowrap;
        }
      }
      .content {
        padding: 0 10px 10px 10px;
        .title {
          font-size: 15px;
          font-weight: 600;
          height: 65px;
          line-height: 21px;
        }
        .price {
          .mainPrice {
            color: rgb(255, 13, 13);
            font-size: 19px;
            font-weight: bold;
            line-height: 22px;
          }
          .withoutVat {
            display: none;
          }
        }
        .alertText {
          font-size: 0.8em;
        }
      }

      .buttons {
        width: 100%;
        button {
          margin-left: auto !important;
        }
      }
    }
    &.nonAvailable {
      .imageWrapper {
        .image {
          img {
            filter: grayscale(100%);
          }
        }
      }
      .content {
        .title {
          color: #828282;
        }
        .subtitle {
          color: #828282;
        }
        .description {
          color: black;
          text-align: center;
        }
        .price {
          .mainPrice {
            color: #828282;
          }
          &.discount {
            .mainPrice {
              color: #828282;
            }
          }
        }
        .alertText {
          color: $alert-text-color;
        }
        .buttons {
          .availabilityMessage {
            display: none;
            @media (max-width: $md) {
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 0.9em;
              line-height: 1.2em;
              flex-direction: column;
              text-align: center;
              strong {
                margin-bottom: 3px;
              }
            }
          }
        }
      }
    }
  }

  &.productCardDiscountWrapper {
    padding: 5px;
  }
}
