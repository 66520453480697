@import "styles/variables";

.slide {
  padding: 10px 15px;

  @media (min-width: $md) {
    padding: 20px;
    margin-bottom: 10px;
  }
}

.prevArrow {
  left: -40px !important;
  @media (min-width: $md) {
    left: -50px !important;
  }
}

.nextArrow {
  right: -40px !important;
  @media (min-width: $md) {
    right: -50px !important;
  }
}
