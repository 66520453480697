.gallery {
    position: relative;

    :global{
        .nonAvailable{
            img{
                filter: grayscale(100%);
            }
        }
    }

    .mainImage {
        img {
            width: 100%;
            height: auto;
        }
    }

    .thumbnails {
        width: 100%;
        margin-top: 10px;
        overflow-x: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;

        a {
            width: 15%;
            min-width: 15%;
            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }
}