@import "styles/variables";

.wrapper {
  margin-bottom: 10px;

  :global(.ant-card-body) {
    padding-bottom: 12px;
  }
  
  & > a {
    display: block;
  }
  picture, img{
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
  }  
  .content{
    height: 160px;
    overflow: hidden;
    .metadata{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: $primary-color;
      font-family: "Nunito Sans", sans-serif;
      font-size: 11px;
      line-height: 1.5em;
      margin-bottom: 10px;
    }
    .title{
      font-family: "Nunito Sans", sans-serif;
      line-height: 1.4em;
      font-weight: bold;
      color: rgb(68, 68, 68);
      margin-bottom: 10px;
      font-size: 1.2em;
    }
    .perex{
      font-size: 12px;
      line-height: 20px;
      font-weight: normal;
      color: rgb(68, 68, 68);
      font-family: Merriweather, sans-serif;
    }
    .paragraph {
      font-family: Merriweather, sans-serif;
      font-size: 11px;
      font-weight: normal;
      line-height: 19px;
    }
  }
  .readMore {
    margin-top: 10px;
    text-align: right;
    color: $primary-color;
    font-size: 12px;
  }
}
