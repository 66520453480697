@import "styles/variables";

.dropdown {
  position: absolute;
  top: 100%;
  padding: 10px 15px;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.04);
  border: solid 0.7px rgba(151, 151, 151, 0.41);
  background-color: #fff;
  font-size: 14px;
  line-height: 30px;
  font-weight: 400;
  font-family: $font-nunito-sans;
  min-width: 250px;
  max-width: 300px;
  z-index: 3;

  &.leftPosition {
    left: unset;
    right: 0;
  }

  &.rightPosition {
    right: unset;
    left: 0;
  }

  &.hidden {
    display: none;
  }

  .checkboxList {
    position: relative;

    .closeButton {
      position: absolute;
      top: 0px;
      right: -5px;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      line-height: 30px;

      label {
        span {
          padding: 0;
          &:first-child {
            width: 15px;
            margin-right: 20px;
          }
        }
      }

      &.add {
        color: $primary-color;

        &:hover {
            color: $primary-color-hover;
        }

        span {
          font-size: 17px;
          width: 15px;
          margin-right: 20px;
          text-align: center;
        }
      }
    }
  }
}
