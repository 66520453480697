// FONTS
$font-nunito-sans: "Nunito Sans", sans-serif;
$font-merriweather: Merriweather, sans-serif;


// OTHER
$footer-color: rgb(57, 12, 99);

$xs: 480px;
$sm: 766px;
$md: 991px;
$lg: 1200px;
$xl: 1440px;

$general-gutter: 15px;
$general-shadow: rgba(0, 0, 0, 0.1) 0px 0px 20px;
$general-radius: 2px;
$general-border: 1px solid rgba(32, 32, 32, 0.20);

$add-to-cart-background: transparent;
$add-to-cart-border: transparent;

$alert-text-color: red;
$discount-color: rgb(230, 59, 69);
$available-color: rgb(40, 167, 69);
$unavailable-color: rgb(220, 53, 69);

$primary-color: rgb(85, 171, 47);
//Christmas theme
// $primary-color: #b41d30;


$primary-color-hover: darken($primary-color, 10%);

$secondary-color: black;

$text-color: rgb(17, 17, 17);
$blue-color: rgb(0, 89, 255);
$grey-color: rgb(183, 183, 183);
$grey-light-color: rgb(238, 238, 238);
$grey-dark-color: rgb(172, 172, 172);

$main-bg-color: rgb(255,255,255);


@mixin secondaryPriceAddon {
    [data-type="secondary"] {
      order: 2;
      &[data-property="price"] {
        &:after {
          content: " s DPH";
        }
      }

      &[data-property="priceWithoutVat"] {
        &:after {
          content: " bez DPH";
        }
      }
    }
  }
