/* Píšte vlastné štýly */
section.custom-insta {
    background: rgb(252,252,243);
    padding: 60px 0;

    .custom-wrapper {
        max-width: 1220px;
        margin-right: auto;
        margin-left: auto;
        padding-right: 10px;
        padding-left: 10px;
    }

    h2 {
        text-align: center;
        font-size: 18px;
        color: black;
        margin: 0 0 30px;
        font-weight: 700;
    }

    img {
        max-width: 100%;
        height: auto;
    }
}

.custom-instafeed {
    display: flex;
    flex-wrap: wrap;

    .custom-wrap {
        width: 16.6666%;
        display: block;
        position: relative;
        overflow: hidden;

        &:before {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 100%;
        }

        img{
            display: block;
            position: absolute;
            object-fit: cover;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            min-width: 100%;
            min-height: 100%;
        }
    }

    .custom-last-block {
        background: rgb( 68,111,33 );
        color: white;
        text-decoration: none;
        cursor: pointer;
        display: block;
        width: 16.6666%;
        position: relative;
        transition: all .2s;
    
        p {
            margin: 10px 0;
        }
    
        &:hover {
            background: #7eb154;
        }
    
        &:before {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 100%;
        }
    
        .custom-content {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-size: 14px;
    
            img {
                width: 60px;
            }
        }
    }
}


@media (max-width: 779px) {
    .custom-instafeed .custom-wrap, .custom-instafeed .custom-last-block {
        width: 25%;
    }
}

@media (max-width: 479px) {
    .custom-instafeed .custom-wrap, .custom-instafeed .custom-last-block {
        width: 50%;
    }
}